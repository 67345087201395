import React, { useState } from "react";
import { Footer, Header } from "..";
import "./ChangePassengerSeat.css";
import PassengerDetailCards from "./PassengerDetailCards";
import SubmitSeat from "./SubmitSeat";
import SelectSeatContainer from "./SelectSeatContainer";

const ChangePassengerSeat = () => {
  const [seatChange, setSeatChange] = useState(false);
  return (
    <div>
      <Header
        header={"Pilih Kursi"}
        back={"/verefiedinputdata"}
        step={"5"}
        close={false}
      />

      <PassengerDetailCards seatChange={seatChange} />
      <SelectSeatContainer setSeatChange={setSeatChange} />
      <SubmitSeat />
      <Footer />
    </div>
  );
};

export default ChangePassengerSeat;
