import React, { useEffect, useState } from "react";
import "../Styles/InputData.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Header, Footer, SubmitedPassengerInfo, Loader } from "../index";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatCurrency } from "../../common/Utils";
import { closeEnrollment } from "../../api/ApiUtils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";
import { StationTicketCard } from "../CommonComponents/StationTicketCard/StationTicketCard";

// getting from env
const baseURL = process.env.REACT_APP_BASEURL;

const VerifiedInputData = () => {
  const navigate = useNavigate();
  const [stationTiciketCard, setStationTiciketCard] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const [passengerDetails, setPassengerDetails] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));
    if (!KAI?.ticketBookingResponse) navigate("/inputdata");
    else {
      const { stationDetails, ticketDetails, customerContactDetails } = KAI;
      let stationTiciketCard = {
        departdate: ticketDetails.departdate,

        originStation: stationDetails.sourceStation.stationname,
        destinationStation: stationDetails.destnationStation.stationname,

        departureTime: ticketDetails.departuretime,
        arrivalTime: ticketDetails.arrivaltime,

        trainName: ticketDetails.trainname,
        wagonClass: ticketDetails.wagonclasscode,
        subClass: ticketDetails.subclass,

        adultPassengers: stationDetails.adultPassengers,
        childPassengers: stationDetails.childPassengers,
        totalPassengers:
          parseInt(stationDetails.adultPassengers) +
          parseInt(stationDetails.childPassengers),
        totalAmount:
          parseInt(stationDetails.adultPassengers) *
          parseInt(ticketDetails.fares[0].amount),
        trainNumber: ticketDetails.noka,
        stasiunOrginCode: ticketDetails.stasiunorgcode,
        stasiunDestCode: ticketDetails.stasiundestcode,
      };
      let verifiedPassenger = {
        paxes: KAI.ticketBookingResponse.data.paxes,
        destinationid: ticketDetails.destinationid,
        originId: ticketDetails.originid,
        subClass: KAI.ticketBookingResponse.data.subclass,
        wagonClassId: KAI.ticketBookingResponse.data.wagonclassid,
        tripId: KAI.ticketBookingResponse.data.tripid,
        paymentcode: KAI.ticketBookingResponse.data.paymentcode,
      };
      setStationTiciketCard(stationTiciketCard);
      setContactDetails(customerContactDetails);
      setPassengerDetails(verifiedPassenger);
    }
  }, []);

  const handleConfirmBooking = () => {
    setLoading(true);

    let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

    const BASE_URL = baseURL;
    let URL = BASE_URL + "/kai/train/ticket/confirmation";

    const body = {
      enrollmentId: enrollmentId,
      paymentCode: passengerDetails.paymentcode,
    };

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Clientid: "Kai-Enrollment",
      },
    };

    axios
      .post(URL, body, headers)
      .then((res) => {
        setLoading(false);
        if (res.data.success) navigate("/redirect");
        else {
          showErrorPopUp({
            ...errorPopUp,
            showPopUp: true,
            message: res.data.message,
          });
          setOpenConfirm(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };
  const handleCloseEnrollment = async () => {
    setOpenCancel(false);
    const response = await closeEnrollment(navigate, "4");
    if (!response.success)
      showErrorPopUp({
        ...errorPopUp,
        showPopUp: true,
        message: response.message,
      });
  };
  const header = "Ringkasan";
  return (
    <div id={"VerifiedInputData"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Header header={header} back={"/inputdata"} step={"4"} />
      <div id={"InputDataForm"} style={{ padding: "16px" }}>
        <StationTicketCard stationTiciketCard={stationTiciketCard} />

        {/* VERIFIED CONTACT DETAILS */}
        <div style={{ marginTop: "24px" }}>
          <Typography
            children={"Data Pemesan (Untuk E - Ticket)"}
            className={"inputSectionLabels"}
          />
        </div>
        <Paper
          className={"dropShadow"}
          style={{
            padding: "12px",
            marginTop: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography
              children={contactDetails.name}
              style={{ fontWeight: "600", fontSize: "12px", color: "#1A1A1A" }}
            />
            <Typography
              children={contactDetails.email}
              style={{ fontSize: "12px", color: "#666", marginTop: "5px" }}
            />
            <Typography
              children={`+${contactDetails.phoneCode} ${contactDetails.phoneNo}`}
              style={{ fontSize: "12px", color: "#666" }}
            />
          </div>
          <CheckCircleRoundedIcon color="success" />
        </Paper>

        <SubmitedPassengerInfo passengerInfoList={passengerDetails} />

        <PricingDetails stationTiciketCard={stationTiciketCard} />

        <Button
          id={"Lanjutkan"}
          fullWidth
          children={"Lanjutkan"}
          variant={"contained"}
          className={"submitBlackButton"}
          onClick={() => setOpenConfirm(true)}
        />
        <Button
          id={"Batalkan"}
          fullWidth
          children={"Batalkan"}
          variant={"contained"}
          className={"submitGreyButton"}
          onClick={() => setOpenCancel(true)}
        />
      </div>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        maxWidth={"sm"}
      >
        <div style={{ padding: "16px" }}>
          <Typography
            style={{ textAlign: "center" }}
            children={
              "Apakah semua data yang dimasukkan sudah benar? Anda tidak dapat mengubah data setelah ini"
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                className={"submitGreyButton"}
                fullWidth
                onClick={() => setOpenConfirm(false)}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={"submitBlackButton"}
                onClick={() => handleConfirmBooking()}
                fullWidth
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        maxWidth={"sm"}
      >
        <div style={{ padding: "16px" }}>
          <Typography
            style={{ textAlign: "center" }}
            children={"Anda tidak dapat mengubah data setelah ini"}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                className={"submitGreyButton"}
                fullWidth
                onClick={() => setOpenCancel(false)}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={"submitBlackButton"}
                onClick={() => handleCloseEnrollment()}
                fullWidth
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Footer />
    </div>
  );
};

export default VerifiedInputData;

const PricingDetails = ({ stationTiciketCard }) => {
  const KAI = JSON.parse(localStorage.getItem("KAI"));

  let { adminfee, netamount } = KAI.ticketBookingResponse.data;
  const totalAmountHarga = formatCurrency(adminfee + netamount);
  adminfee = formatCurrency(adminfee || 0);
  netamount = formatCurrency(netamount || 0);

  return (
    <div style={{ margin: "24px 0 0 0" }}>
      <Typography children={"Rincian Harga"} className={"inputSectionLabels"} />
      <div style={{ marginTop: "16px" }}>
        <Accordion id={"RincianHarga-dropdown"} className={"dropShadow"}>
          <AccordionSummary style={{ borderBottom: "1px solid #DDD" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <KeyboardArrowDownIcon
                  style={{ marginRight: "12px" }}
                  className={"iconArrowInSelectInput"}
                />
                <Typography
                  id={"Harga-Yang-Anda-Bayar"}
                  children={"Harga yang Anda bayar"}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
              </div>
              <Typography
                id={"Harga-Yang-Anda-Bayar-value"}
                children={`${totalAmountHarga}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#1A1A1A",
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="Argo-Dewasa"
                  children={`Argo (Dewasa) x ${stationTiciketCard.adultPassengers}`}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
                <Typography
                  id="Argo-Rp"
                  children={`${netamount}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="Argo-Bayi"
                  children={`Argo (Bayi) x ${stationTiciketCard.childPassengers}`}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
                <Typography
                  id="GRATIS"
                  children={"GRATIS"}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#179D4F",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="Argo-Bayi"
                  children={`Convenience Fee`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
                <Typography
                  id="GRATIS"
                  children={`${adminfee}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
