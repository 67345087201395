export const formatCurrency = (
  num,
  withoutSpace = false,
  locales = "id-ID",
  options = {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }
) => {
  const numberFormatting = new Intl.NumberFormat(locales, options).format(num);

  if (locales === "id-ID" && options?.currency === "IDR" && withoutSpace) {
    return numberFormatting.replace(/\s+/g, "");
  }

  return numberFormatting;
};

export const getWagonClass = (code, subclass) => {
  switch (code) {
    case "EKS":
      return `Eksekutif (${subclass})`;
    case "EKO":
      return `Ekonomi (${subclass})`;
    case "BIS":
      return `Bussines (${subclass})`;
    case "PRE":
      return `Premium (${subclass})`;
    case "LUX":
      return `LUXURY (${subclass})`;
    case "PRI":
      return `Priority (${subclass})`;
    default:
      return `${code} (${subclass})`;
  }
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};
export const getEnrollmentId = () => {
  if (isEmpty(JSON.parse(localStorage.getItem("enrollmentId")))) {
    if (!isEmpty(window.location.search.split("=")[1])) {
      localStorage.setItem(
        "enrollmentId",
        JSON.stringify(window.location.search.split("=")[1])
      );
    }
  }
  return JSON.parse(localStorage.getItem("enrollmentId"));
};
