import { createTheme } from "@mui/material/styles"

const typography = {
    // fontFamily: `'Inter', sans-serif`
  }
const theme = createTheme({
    palette: {
        red: {
          main: '#A12027'
        }
    },
    typography,
})

export default theme
