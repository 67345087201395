import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { getWagonClass } from "../../../common/Utils";
import { nanoid } from "nanoid";
import changePage from "./SeatSlideChange";
import {
  calculateHeight,
  commonStyle,
  getBackground,
  getBorder,
  getPassengerIndex,
  handleSeatClick,
  initSeatLayout,
  seatColumnStyle,
  useStyle,
} from "./seatLayoutUtils";

const OneByTwo = ({
  selectedClass,
  setSelectedClass,
  setSeatChange,
  currentSelectedClassLayout,
}) => {
  const [classSeatArray, setClassSeatArray] = useState([]);
  const [passengerList, setPassengerList] = useState([]);
  const [currentPassengerIndex, setCurrentPassengerIndex] = useState("");

  const [noOfRows, setNoOfRows] = useState({});
  const [height, setHeight] = useState("100px");

  const classes = useStyle();

  useEffect(() => {
    const layoutProps = {
      getWagonClass,
      setCurrentPassengerIndex,
      setPassengerList,
      setClassSeatArray,
    };
    initSeatLayout(layoutProps);
  }, []);

  useEffect(() => {
    if (currentSelectedClassLayout) {
      const heightProps = {
        setHeight,
        currentSelectedClassLayout,
        setNoOfRows,
      };
      calculateHeight(heightProps);
    }
  }, [currentSelectedClassLayout]);

  return (
    <Carousel
      autoPlay={false}
      swipe={false}
      navButtonsAlwaysVisible={true}
      indicatorContainerProps={{
        style: { marginTop: height, visibility: "hidden" },
      }}
      animation={"slide"}
      navButtonsProps={{ className: classes.carouselNavButtons }}
      next={(next, active) => {
        if (active || next) changePage("next", selectedClass, setSelectedClass);
      }}
      prev={(prev, active) => {
        if (active || prev) changePage("prev", selectedClass, setSelectedClass);
      }}
    >
      {classSeatArray.map((v, i) => (
        <div key={`row-seat-one-by-two-container-${nanoid()}`}>
          <RowSeat
            selectedClass={selectedClass}
            passengerList={passengerList}
            setPassengerList={setPassengerList}
            currentPassengerIndex={currentPassengerIndex}
            setSeatChange={setSeatChange}
            noOfRows={noOfRows}
          />
        </div>
      ))}
    </Carousel>
  );
};

const RowIndex = () => {
  return (
    <Grid container justifyContent={"center"} id="grid">
      <Grid item style={{ ...commonStyle, marginRight: "10px" }}>
        {" "}
      </Grid>
      <Grid item style={seatColumnStyle}>
        A
      </Grid>
      <Grid item style={{ width: "22px", height: "32px" }}>
        {" "}
      </Grid>
      <Grid item style={seatColumnStyle}>
        B
      </Grid>
      <Grid item style={seatColumnStyle}>
        C
      </Grid>
    </Grid>
  );
};

const RowSeat = ({
  selectedClass,
  passengerList,
  setPassengerList,
  currentPassengerIndex,
  setSeatChange,
  noOfRows,
}) => {
  return (
    <div>
      <RowIndex />
      {noOfRows?.rows?.map((data, i) => {
        const classSeatAvailableList = noOfRows.available;
        const classSeatNotAvailableList = noOfRows.notAvailable;
        const length = noOfRows.rows.length * 3;
        const SEAT_ROW = data + 1;
        const isVisibleA = i * 3 + 1 <= length;
        const isVisibleB = i * 3 + 2 <= length;
        const isVisibleC = i * 3 + 3 <= length;
        const seatProps = {
          passengerIndex: currentPassengerIndex,
          passengerList,
          selectedClass,
          setPassengerList,
          setSeatChange,
          classSeatAvailableList,
        };

        return (
          <div id={data} key={data}>
            <Grid
              container
              justifyContent={"center"}
              style={{ marginBottom: "24px" }}
            >
              {/* --- INDEX --- */}
              <Grid
                item
                style={{
                  height: "32px",
                  width: "32px",
                  padding: 5,
                  marginRight: 10,
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                {data + 1}
              </Grid>

              {/* --- A --- */}
              <Grid
                id={SEAT_ROW + "A"}
                item
                className={"seatBox"}
                style={{
                  visibility: isVisibleA ? "" : "hidden",
                  background: getBackground(
                    passengerList,
                    classSeatAvailableList,
                    classSeatNotAvailableList,
                    SEAT_ROW + "A",
                    selectedClass + "," + SEAT_ROW + "A"
                  ),
                  border: getBorder(
                    passengerList,
                    selectedClass + "," + SEAT_ROW + "A"
                  ),
                }}
                onClick={() => {
                  seatProps.seat = SEAT_ROW + "A";
                  handleSeatClick(seatProps);
                }}
              >
                {getPassengerIndex(
                  passengerList,
                  selectedClass + "," + SEAT_ROW + "A"
                )}
              </Grid>

              {/* --- ROW GAP --- */}
              <Grid item style={{ height: "32px", width: "22px" }}>
                {" "}
              </Grid>

              {/* --- B --- */}
              <Grid
                id={SEAT_ROW + "B"}
                item
                className={"seatBox"}
                style={{
                  visibility: isVisibleB ? "" : "hidden",
                  background: getBackground(
                    passengerList,
                    classSeatAvailableList,
                    classSeatNotAvailableList,
                    SEAT_ROW + "B",
                    selectedClass + "," + SEAT_ROW + "B"
                  ),
                  border: getBorder(
                    passengerList,
                    selectedClass + "," + SEAT_ROW + "B"
                  ),
                }}
                onClick={() => {
                  seatProps.seat = SEAT_ROW + "B";
                  handleSeatClick(seatProps);
                }}
              >
                {getPassengerIndex(
                  passengerList,
                  selectedClass + "," + SEAT_ROW + "B"
                )}
              </Grid>

              {/* --- C --- */}
              <Grid
                id={SEAT_ROW + "C"}
                item
                className={"seatBox"}
                style={{
                  visibility: isVisibleC ? "" : "hidden",
                  background: getBackground(
                    passengerList,
                    classSeatAvailableList,
                    classSeatNotAvailableList,
                    SEAT_ROW + "C",
                    selectedClass + "," + SEAT_ROW + "C"
                  ),
                  border: getBorder(
                    passengerList,
                    selectedClass + "," + SEAT_ROW + "C"
                  ),
                }}
                onClick={() => {
                  seatProps.seat = SEAT_ROW + "C";
                  handleSeatClick(seatProps);
                }}
              >
                {getPassengerIndex(
                  passengerList,
                  selectedClass + "," + SEAT_ROW + "C"
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default OneByTwo;
