import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { blueGrey } from "@mui/material/colors";
import { getWagonClass } from "../../../common/Utils";

const SelectWagon = ({ selectedClass, setSelectedClass }) => {
  const [openList, setOpenList] = useState(false);
  const [classSeatArray, setClassSeatArray] = useState([]);

  useEffect(() => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    setClassSeatArray(KAI.seatList.list.classSeatData);
  }, []);

  return (
    <div style={{ padding: "24px", textAlign: "center" }}>
      <Button
        id="wagon-list-button"
        children={selectedClass}
        className={"submit"}
        style={{ padding: "0 24px" }}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => setOpenList(true)}
      />
      <WagonClassList
        classSeatArray={classSeatArray}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        openList={openList}
        setOpenList={setOpenList}
      />
    </div>
  );
};

const WagonClassList = ({
  classSeatArray,
  openList,
  setOpenList,
  selectedClass,
  setSelectedClass,
}) => {
  return (
    <div
      style={{
        background: "#bbbbbbd6",
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        zIndex: "2",
        display: openList ? "" : "none",
      }}
    >
      <div
        style={{
          maxWidth: "640px",
          margin: "0 auto",
          height: "100vh",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            background: "#FFF",
            bottom: "0",
            width: "100%",
            borderRadius: "8px 8px 0 0 ",
          }}
        >
          <div className={"displayFlex"} style={{ padding: "16px" }}>
            <Typography
              children={"Pilih Kereta"}
              style={{ fontWeight: "700", fontSize: "20px", color: "#1A1A1A" }}
            />
            <IconButton
              id="close-wagon-list"
              onClick={() => setOpenList(false)}
            >
              {" "}
              <CloseIcon />{" "}
            </IconButton>
          </div>
          {classSeatArray.map((k, id) => {
            let wagonClassName = Object.keys(k)[0];
            wagonClassName = getWagonClass(
              wagonClassName.split("-")[0],
              wagonClassName.split("-")[1]
            );
            return (
              <FormControlLabel
                id={`radio-button-wagon-class-${id}`}
                checked={selectedClass === wagonClassName}
                value={wagonClassName}
                control={
                  <Radio
                    sx={{
                      color: blueGrey[900],
                      "&.Mui-checked": {
                        color: "#1A1A1A",
                      },
                    }}
                    value={wagonClassName}
                  />
                }
                label={wagonClassName}
                onClick={(e) => {
                  setSelectedClass(e.target.value);
                  setOpenList(false);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 20px 20px",
                }}
                key={`wagon-class-container-${id}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectWagon;
